<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        :visible="visrecord"
        title="审核进度"
        @ok="oncanrecord"
        @cancel="oncanrecord"
      >
        <a-timeline>
          <a-timeline-item
            v-for="(item, index) in progresslist"
            :key="index"
            :color="
              item.operate == 1
                ? 'blue'
                : item.operate == 2
                ? 'green'
                : item.operate == 3
                ? 'red'
                : 'blue'
            "
          >
            {{ item.positionName }}审批
            <span v-show="item.auditorName != null"
              >{{ item.auditorName }}&nbsp;</span
            >
            <span v-show="item.auditorTime != null"
              >{{ item.auditorTime | time }}&nbsp;</span
            >
            <span>{{
              item.operate == 2 ? '通过' : item.operate == 3 ? '拒绝' : '待审批'
            }}</span>
          </a-timeline-item>
        </a-timeline>
      </a-modal>
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>财务管理</a-breadcrumb-item>
              <a-breadcrumb-item>账单管理</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          min-width: 1210px;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <!-- <a-select
                  v-if="i == '快速筛选'"
                  style="width: 100%"
                  default-value="0"
                >
                  <a-select-option value="0"> 当月账单 </a-select-option>
                  <a-select-option value="1"> 已出未结清 </a-select-option>
                  <a-select-option value="2"> 逾期未结清 </a-select-option>
                  <a-select-option value="3"> 自定义 </a-select-option>
                </a-select> -->
                <a-select
                  v-model="params['cb.state'].value"
                  v-if="i == '出账状态'"
                  placeholder="请选择出账状态"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未出账 </a-select-option>
                  <a-select-option value="1"> 已出账 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['cb.confirm'].value"
                  v-else-if="i == '结清状态'"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未结清 </a-select-option>
                  <a-select-option value="1"> 已结清 </a-select-option>
                </a-select>
                <div
                  v-else-if="i == '支付日期'"
                  class="flex-d-row"
                  style="display: inline-block"
                >
                  <a-date-picker
                    placeholder="请输入开始日期"
                    style="width: 173.5px"
                    v-model="params['cb.pay_date'].value"
                  /><span class="a-col-span">-</span>
                  <a-date-picker
                    placeholder="请输入结束日期"
                    style="width: 173.5px"
                    v-model="params['cb.pay_date#2'].value"
                  />
                </div>
                <div
                  v-else-if="i == '账单日期'"
                  class="flow-d-row"
                  style="display: inline-block"
                >
                  <a-date-picker
                    placeholder="请输入开始日期"
                    v-model="params['cb.bill_date'].value"
                    style="width: 173.5px"
                  /><span class="a-col-span">-</span>
                  <a-date-picker
                    v-model="params['cb.bill_date#2'].value"
                    style="width: 173.5px"
                    placeholder="请输入结束日期"
                  />
                </div>
                <div
                  v-else-if="i == '账单金额'"
                  class="flow-d-row"
                  style="display: inline-block"
                >
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.total_fee'].value"
                    placeholder="请输入最小金额"
                  /><span class="a-col-span">-</span>
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.total_fee#2'].value"
                    placeholder="请输入最大金额"
                  />
                </div>
                <div
                  v-else-if="i == '已支付金额'"
                  class="flow-d-row"
                  style="display: inline-block"
                >
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.payed'].value"
                    placeholder="请输入最小金额"
                  /><span class="a-col-span">-</span>
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.payed#2'].value"
                    placeholder="请输入最大金额"
                  />
                </div>
                <a-input
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  v-else
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        style="background: #fff; padding: 24px; margin: 0; min-width: 1210px"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <span
              style="position: absolute; top: 22px; left: 76px; color: orange"
              >{{ calltotal }}</span
            >
            <span
              style="position: absolute; top: 22px; left: 198px; color: orange"
              >{{ searchNums.num0 }}</span
            >
            <span
              style="position: absolute; top: 22px; left: 332px; color: orange"
              >{{ searchNums.num1 }}</span
            >
            <span
              style="position: absolute; top: 22px; left: 465px; color: orange"
              >{{ searchNums.num2 }}</span
            >
            <a-tabs :activeKey="keys" @change="callback">
              <a-tab-pane key="-1" tab="所有账单"> </a-tab-pane>
              <a-tab-pane key="1" tab="当月账单"> </a-tab-pane>
              <a-tab-pane key="2" tab="已出未结清"> </a-tab-pane>
              <a-tab-pane key="3" tab="逾期未结清"> </a-tab-pane>
            </a-tabs>
          </div>
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="paymentInfolist"
            :rowClassName="rowClassName"
          >
            <span slot="state" slot-scope="text">
              <a-badge
                :status="text | statusTypeFilter"
                :text="text | statusFilter"
              />
            </span>
            <span slot="confirm" slot-scope="text">
              <a-badge
                :status="text | confirmTypeFilter"
                :text="text | confirmFilter"
              />
            </span>
            <span slot="overd" slot-scope="text, record">
              <a-badge
                :status="record | rentStatusTypeoverdue"
                :text="record | rentStatusoverdue"
              />
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">账单明细</a>
              &nbsp;
              <a-dropdown
                v-show="record.state == 1 && record.confirm == 0"
                placement="topCenter"
                :trigger="['click']"
              >
                <a @click="e => e.preventDefault()"> 核销&nbsp; </a>
                <a-menu slot="overlay">
                  <a-menu-item key="0">
                    <a @click="showDrawer(record)">{{
                      record.state == 1 && record.confirm == 0 ? '汇款' : ''
                    }}</a>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <a @click="showibillDrawer(record)">{{
                      record.state == 1 && record.confirm == 0 ? '意向金' : ''
                    }}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

              <a @click="onrecord(record)">核销记录&nbsp;</a>
              <a v-show="record.state == 1" @click="onrecords(record)"
                >审核进度</a
              >
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
          <a-modal
            :title="title"
            :visible="modelvisible"
            width="1100px"
            @ok="onmodelClose"
            @cancel="onmodelClose"
          >
            <a-descriptions v-show="Id == 1" :column="5" title="">
              <a-descriptions-item label="客户">
                {{ list.clientName }}
              </a-descriptions-item>
              <a-descriptions-item label="铺位">
                {{ list.storeNames }}
              </a-descriptions-item>
              <a-descriptions-item label="经营品牌">
                {{ list.brandName }}
              </a-descriptions-item>
              <a-descriptions-item label="账单日期">
                {{ list.billDate }}
              </a-descriptions-item>
              <a-descriptions-item label="支付日期">
                {{ list.payDate }}
              </a-descriptions-item>
              <a-descriptions-item label="账单总计">
                {{ list.totalFee }}元
              </a-descriptions-item>
              <a-descriptions-item label="已支付">
                {{ list.payed }}元
              </a-descriptions-item>
              <a-descriptions-item label="未支付">
                {{ list.totalFee - list.payed }}元
              </a-descriptions-item>
            </a-descriptions>
            <a-table
              v-if="Id == 1"
              rowKey="id"
              childrenColumnName="child"
              :columns="modelcolumns"
              :data-source="data"
              class="components-table-demo-nested"
            >
              <span slot="startDate" slot-scope="text, record"
                >{{ record.startDate }}-{{ record.endDate }}
              </span>
            </a-table>
            <a-table
              v-else
              rowKey="id"
              childrenColumnName="child"
              :columns="recordcolumns"
              :data-source="recordlist"
            >
              <span slot="createTime" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="proof" slot-scope="text">
                <a-dropdown :trigger="['click']" placement="bottomCenter">
                  <a-button size="small" @click="recordimage(text)"
                    >查看凭证</a-button
                  >
                  <a-menu slot="overlay">
                    <a-menu-item
                      v-show="text != '[]'"
                      @click="images(i.url)"
                      v-for="i in proof"
                      :key="i"
                    >
                      <a>{{ i.name }}</a>
                    </a-menu-item>
                    <a-menu-item v-show="text == '[]'"> 暂无凭证 </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
              <span slot="id" slot-scope="text, record">
                <a-button size="small" @click="onhistory(record)"
                  >审核记录</a-button
                >
              </span>
              <span slot="state" slot-scope="text">
                <a-badge
                  :status="text | stateTypeFilter"
                  :text="text | stateFilter"
                />
              </span>
            </a-table>
          </a-modal>
          <a-modal
            :width="600"
            title="审核记录"
            :visible="hisvisible"
            @ok="onclosehistory"
            @cancel="onclosehistory"
          >
            <a-table
              rowKey="note"
              :columns="historycolumns"
              :data-source="historylist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
            </a-table>
          </a-modal>
          <a-modal
            title="核销"
            :visible="visible"
            width="600px"
            @ok="onSubmit"
            @cancel="onClose"
          >
            <a-form-model
              v-if="value == 0"
              ref="ruleForm"
              :rules="rules"
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item prop="payType" label="支付方式">
                <a-select style="width: 100%" v-model="form.payType">
                  <a-select-option value="0"> 支付宝 </a-select-option>
                  <a-select-option value="1"> 微信 </a-select-option>
                  <a-select-option value="2"> POS机 </a-select-option>
                  <a-select-option value="3"> 银行转账 </a-select-option>
                  <a-select-option value="4"> 现金 </a-select-option>
                  <a-select-option value="5"> 其他 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item ref="fee" prop="fee" label="金额">
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.fee"
                />
              </a-form-model-item>
              <a-form-model-item
                ref="payByName"
                prop="payByName"
                label="付款人"
              >
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.payByName"
                />
              </a-form-model-item>
              <a-form-model-item prop="payTime" label="付款时间">
                <a-date-picker
                  style="width: 100%"
                  v-model="form.payTime"
                  @change="onChange"
                />
              </a-form-model-item>
              <a-form-model-item ref="code" prop="code" label="交易单号">
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.code"
                />
              </a-form-model-item>
              <a-form-model-item ref="note" prop="note" label="备注">
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.note"
                />
              </a-form-model-item>
              <a-form-model-item label="凭证">
                <div class="clearfix">
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :file-list="proof"
                    @preview="handlePreview"
                    :customRequest="handleChangeimage"
                    @change="handleChangeimages"
                  >
                    <div v-if="proof.length < 5">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </div>
              </a-form-model-item>
            </a-form-model>
            <div v-else>
              <a-descriptions layout="vertical" :column="4" title="账单信息">
                <a-descriptions-item label="意向金总额">
                  {{ ibilllist.totalFee }}元
                </a-descriptions-item>
                <a-descriptions-item label="结清状态">
                  {{ ibilllist.confirm == 0 ? '未结清' : '已结清' }}
                </a-descriptions-item>
                <a-descriptions-item label="已支付">
                  {{ ibilllist.payed }}元
                </a-descriptions-item>
                <a-descriptions-item label="已使用">
                  {{ ibilllist.used }}元
                </a-descriptions-item>
                <a-descriptions-item label="意向金余额">
                  {{ ibilllist.payed - ibilllist.used }}元
                </a-descriptions-item>
              </a-descriptions>
              <a-form-model
                ref="ruleForm"
                :rules="rules"
                :model="form"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item ref="fee" prop="fee" label="核销金额">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.fee"
                  />
                </a-form-model-item>
              </a-form-model>
            </div>
          </a-modal>
          <a-modal :visible="previewV" :footer="null" @cancel="handleC">
            <img alt="example" style="width: 100%" :src="rimages" />
          </a-modal>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const historycolumns = [
  {
    title: '审核人',
    dataIndex: 'auditorName',
  },
  {
    title: '审核备注',
    dataIndex: 'note',
  },
  {
    title: '审核时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
  },
]
const columns = [
  {
    title: '客户',
    dataIndex: 'clientName',
  },
  {
    title: '账单日期',
    dataIndex: 'billDate',
  },
  {
    title: '支付日期',
    dataIndex: 'payDate',
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
  },
  {
    title: '已支付',
    dataIndex: 'payed',
  },
  {
    title: '出账状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '结清状态',
    dataIndex: 'confirm',
    scopedSlots: { customRender: 'confirm' },
  },
  {
    title: '逾期状态',
    dataIndex: 'payDate',
    scopedSlots: { customRender: 'overd' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const modelcolumns = [
  {
    title: '费用名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '起止时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' },
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
  },
]
const recordcolumns = [
  {
    title: '付款人',
    dataIndex: 'payByName',
  },
  {
    title: '金额',
    dataIndex: 'fee',
  },
  {
    title: '备注',
    dataIndex: 'note',
  },
  {
    title: '核销人',
    dataIndex: 'creatorName',
  },
  {
    title: '核销时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '凭证',
    dataIndex: 'proof',
    scopedSlots: { customRender: 'proof' },
  },
  {
    title: '审核记录',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '草稿',
  },
  1: {
    status: 'processing',
    text: '提交审核',
  },
  2: {
    status: 'success',
    text: '审核通过',
  },
  3: {
    status: 'error',
    text: '审核拒绝',
  },
}
const stateMap = {
  0: {
    status: 'warning',
    text: '未出账',
  },
  1: {
    status: 'processing',
    text: '已出账',
  },
}
const confirmMap = {
  0: {
    status: 'warning',
    text: '未结清',
  },
  1: {
    status: 'processing',
    text: '已结清',
  },
}
const overdue = {
  0: {
    status: 'success',
    text: '未逾期',
  },
  1: {
    status: 'processing',
    text: '逾期已结清',
  },
  2: {
    status: 'error',
    text: '逾期未结清',
  },
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
import moment from 'moment'
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      moment,
      previewVisible: false,
      previewV: false,
      previewImage: '',
      visible: false,
      historylist: [],
      aaa: false,
      placement: 'right',
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      data: [],
      recordlist: [],
      title: '',
      modelcolumns,
      recordcolumns,
      modelvisible: false,
      visrecord: false,

      textlist: [
        '账单日期',
        '支付日期',
        '已支付金额',
        '账单金额',
        '客户名称',
        '结清状态',
        '出账状态',
      ],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      params: {
        current: 1,
        pageSize: 10,
        'cc.name': {
          value: '',
          op: '%like%',
        },
        'cb.bill_date': {
          value: null,
          op: '>=',
        },
        'cb.bill_date#2': {
          value: null,
          op: '<=',
        },
        'cb.pay_date': {
          value: null,
          op: '>=',
        },
        'cb.pay_date#2': {
          value: null,
          op: '<=',
        },
        'cb.total_fee': {
          value: undefined,
          op: '>=',
        },
        'cb.total_fee#2': {
          value: undefined,
          op: '<=',
        },
        'cb.payed': {
          value: undefined,
          op: '>=',
        },
        'cb.payed#2': {
          value: undefined,
          op: '<=',
        },
        'cb.state': {
          value: undefined,
          op: '=',
        },
        'cb.confirm': {
          value: undefined,
          op: '=',
        },
      },
      total: 1,
      searchNums: {},
      paymentInfolist: [],
      selectedRowKey: [],
      Id: '',
      hisvisible: false,
      historycolumns,
      list: [],
      proof: [],
      ibilllist: [],
      progresslist: [],
      rimages: '',
      keys: '-1',
      calltotal: '',
      form: {
        payType: '',
        payTime: null,
        proof: '',
        id: '',
        billId: '',
        fee: '',
        code: '',
        payByName: '',
        note: '',
      },
      e: '',
      rules: {
        payTime: [
          {
            required: true,
            message: '付款时间不能为空',
            trigger: 'change',
          },
        ],
        payType: [
          {
            required: true,
            message: '支付方式不能为空',
            trigger: 'change',
          },
        ],
        fee: [
          {
            required: true,
            message: '金额不能为空',
            trigger: 'blur',
          },
        ],
        payByName: [
          {
            required: true,
            message: '付款人不能为空',
            trigger: 'blur',
          },
        ],
        note: [
          {
            required: true,
            message: '备注不能为空',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '交易单号不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    rentStatusoverdue(type) {
      if (type == null) {
        return ' '
      } else {
        if (moment(moment().format('YYYY-MM-DD')).isBefore(type.payDate)) {
          return overdue[0].text
        } else {
          if (type.confirm == '0') {
            return overdue[2].text
          } else {
            return overdue[1].text
          }
        }
      }
    },
    rentStatusTypeoverdue(type) {
      if (type == null) {
        return ' '
      } else {
        if (moment(moment().format('YYYY-MM-DD')).isBefore(type.payDate)) {
          return overdue[0].status
        } else {
          if (type.confirm == '0') {
            return overdue[2].status
          } else {
            return overdue[1].status
          }
        }
      }
    },
    stateFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].text
      }
    },
    stateTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].status || ''
      }
    },
    statusFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return stateMap[type].text
      }
    },
    statusTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return stateMap[type].status || ''
      }
    },
    confirmFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return confirmMap[type].text
      }
    },
    confirmTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return confirmMap[type].status || ''
      }
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      this.keys = '-1'
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['cc.name'].value = values.客户名称
      })
      this.getPaymentInfo()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['cb.bill_date'].value = null
      this.params['cb.bill_date#2'].value = null
      this.params['cb.pay_date'].value = null
      this.params['cb.pay_date#2'].value = null
      this.params['cb.total_fee'].value = undefined
      this.params['cb.total_fee#2'].value = undefined
      this.params['cb.payed'].value = undefined
      this.params['cb.payed#2'].value = undefined
      this.params['cb.state'].value = undefined
      this.params['cb.confirm'].value = undefined
    },

    toggle() {
      this.expand = !this.expand
    },
    onclosehistory() {
      this.hisvisible = false
    },
    onhistory(e) {
      this.hisvisible = true
      this.getfinbillhistory(e.id)
    },
    async getfinbillhistory(id) {
      try {
        const res = await http.getfinbillhistory(id)
        const { success, data } = res.data
        if (success) {
          this.historylist = data.audits
          //   console.log(this.historylist);
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    callback(e) {
      if (e == undefined) {
        this.keys = '-1'
      } else {
        this.keys = e
      }
      this.params.current = 1
      this.params['cb.bill_date'].value = ''
      this.params['cb.bill_date#2'].value = ''
      this.params['cb.pay_date'].value = ''
      this.params['cb.pay_date#2'].value = ''
      this.params['cb.state'].value = ''
      this.params['cb.confirm'].value = ''
      if (e == 1) {
        const startDate = moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD')
        const endDate = moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD')
        this.params['cb.bill_date'].value = startDate
        this.params['cb.bill_date#2'].value = endDate
      } else if (e == 2) {
        this.params['cb.state'].value = '1'
        this.params['cb.confirm'].value = '0'
        this.params['cb.pay_date'].value = moment().format('YYYY-MM-DD')
      } else if (e == 3) {
        this.params['cb.state'].value = '1'
        this.params['cb.confirm'].value = '0'
        this.params['cb.pay_date#2'].value = moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.getPaymentInfo()
    },
    recordimage(e) {
      this.proof = JSON.parse(e)
      var a = []
      this.proof.forEach(item => {
        if (this.$store.state.uploadType == 1) {
          a.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: nanoid(),
            status: 'done',
            url: `api/sccn/public/download/${item.name}?type=p&cid=${this.$store.state.customerId}`,
          })
        } else {
          let url
          url = client.signatureUrl(item.name)
          a.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: nanoid(),
            status: 'done',
            url: url,
          })
        }
      })
      this.proof = a
    },
    images(e) {
      this.rimages = e
      setTimeout(() => {
        this.previewV = true
      }, 100)
    },
    handleC() {
      this.previewV = false
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChangeimages({ fileList }) {
      if (this.proof.length > fileList.length) {
        this.proof = fileList
        this.form.proof = JSON.stringify(fileList)
      }
    },
    handleChangeimage(file) {
      var data = file.file
      let name = file.file.name.split('.')
      this.putObject(
        client,
        nanoid(),
        name[name.length - 1],
        data,
        file.file.name
      )
    },
    async putObject(client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.proof.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: id,
            realName: realName,
            status: 'done',
            url: url,
          })
        })
        this.form.proof = JSON.stringify(this.proof)
      } catch (e) {
        console.log(e)
      }
    },
    onmodelClose() {
      this.modelvisible = false
    },
    onClose() {
      this.visible = false
      this.proof = []
      this.Id = ''
      this.form = {
        payType: '',
        proof: '',
        id: '',
        billId: '',
        fee: '',
        code: '',
        payByName: '',
        note: '',
      }
      this.$refs.ruleForm.resetFields()
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (this.value == 0) {
            this.putPaymentInfo(this.form)
          } else {
            this.getibillconfirm2(this.form)
          }
        } else {
          return false
        }
      })
    },
    // 编辑
    onEdit(item) {
      console.log(item)
      this.list = item
      this.title = '账单明细'
      this.Id = 1
      this.modelvisible = true
      this.getPaymentInfoId(item.id)
    },
    onrecord(item) {
      this.Id = 2
      this.title = '核销记录'
      this.modelvisible = true
      this.getPaymentInforecord(item.id)
    },
    async putPaymentInfo(form) {
      try {
        const res = await http.putPaymentInfo(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onicon() {
      window.open('http://www.fontawesome.com.cn/faicons/')
    },
    onpagesize(e) {
      this.params.current = e
      this.getPaymentInfo()
    },
    showDrawer(e) {
      this.value = 0
      this.form.billId = e.id
      this.visible = true
    },
    showibillDrawer(e) {
      this.form.billId = e.id
      this.value = 1
      this.getibillInfoId(e.id)
    },
    async getPaymentInfo() {
      try {
        const res = await http.getPaymentInfo(this.params)
        const { success, data } = res.data
        if (success) {
          this.paymentInfolist = data.bills.records
          this.total = data.bills.total
          if (this.keys == '-1') {
            this.calltotal = data.bills.total
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //标签个数
    async getbillsearchNum() {
      try {
        const res = await http.getbillsearchNum(this.params)
        const { success, data } = res.data
        if (success) {
          this.searchNums = data
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getPaymentInfoId(id) {
      try {
        const res = await http.getPaymentInfoId(id)
        const { success, data } = res.data
        if (success) {
          this.data = data.bills
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getibillInfoId(id) {
      try {
        const res = await http.getibillInfoId(id)
        const { success, data, msg } = res.data
        if (success) {
          this.visible = true
          this.ibilllist = data.bill
        } else {
          this.visible = false
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getibillconfirm2() {
      try {
        const res = await http.getibillconfirm2(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getPaymentInforecord(id) {
      try {
        const res = await http.getPaymentInforecord(id)
        const { success, data } = res.data
        if (success) {
          this.recordlist = data.flows.filter(item => item.deleted != 1)
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onrecords(e) {
      //   console.log(e);
      this.visrecord = true
      this.getauditrecords(3, e.id)
    },
    oncanrecord() {
      this.visrecord = false
      setTimeout(() => {
        this.progresslist = []
      }, 100)
    },
    async getauditrecords(linkType, linkId) {
      try {
        const res = await http.getauditrecords(linkType, linkId)
        const { success, data } = res.data
        if (success) {
          this.progresslist = data.record
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getPaymentInfo()
      }, 200)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getbillsearchNum()
    this.callback()
    this.getsts()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form {
  .ant-form-item {
    height: 80px;
    margin-bottom: 15px;
  }
}
.ant-form-item {
  height: 50px;
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 123px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
</style>
